import React from 'react';
import img1 from "../assets/img/gallery/1.jpeg"
import img2 from "../assets/img/gallery/2.jpeg"
import img3 from "../assets/img/gallery/3.jpeg"
import img4 from "../assets/img/gallery/4.jpeg"
import img5 from "../assets/img/gallery/5.jpeg"
import img6 from "../assets/img/gallery/6.jpeg"
import img7 from "../assets/img/gallery/7.jpeg"
import img8 from "../assets/img/gallery/8.jpeg"
import img9 from "../assets/img/gallery/9.jpeg"
import img10 from "../assets/img/gallery/10.jpeg"
import img11 from "../assets/img/gallery/11.jpeg"
import img12 from "../assets/img/gallery/12.jpeg"

function Gallery(props) {

    return (
        <div className="div-media">
            <div className="container-fluid my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="section-title mb-4">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">
                            Les médias
                        </h5>
                        <h4 className="display-5 mb-0">DASS À L'ACTION</h4>
                        <h4 className="text-body fst-italic mb-4">Ensemble pour la population</h4>
                    </div>
                    <div className="row gallery">
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img1}>
                                <img className="img-fluid"
                                     src={img1}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img2}>
                                <img className="img-fluid"
                                     src={img2}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img3}>
                                <img className="img-fluid"
                                     src={img3}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img4}>
                                <img className="img-fluid"
                                     src={img4}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img5}>
                                <img className="img-fluid"
                                     src={img5}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img6}>
                                <img className="img-fluid"
                                     src={img6}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img7}>
                                <img className="img-fluid"
                                     src={img7}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img8}>
                                <img className="img-fluid"
                                     src={img8}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img9}>
                                <img className="img-fluid"
                                     src={img9}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img10}>
                                <img className="img-fluid"
                                     src={img10}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img11}>
                                <img className="img-fluid"
                                     src={img11}/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center pt-5">
                            <a href={img12}>
                                <img className="img-fluid img-dass"
                                     src={img12}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;
