import './App.css';
import {Route, Routes} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Navbar from "./components/Navbar";
import Topbar from "./components/Topbar";
import React from "react";
import Suivi from "./pages/Suivi";

function App() {
    return (
        <>

            {/*<Topbar/>*/}
            <Navbar/>
            <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/suivi" element={<Suivi/>}/>
            </Routes>
        </>
    );
}

export default App;
