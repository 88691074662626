import React, {useState} from 'react';
import {TextField} from "@mui/material";
import {useFormik} from "formik";
import Spinner from "../components/Spinner";
import {findInquiryByCode} from "../services/inquiry";
import Details from "../components/Details";

function FormSuivi(props) {
    const [loading, setLoading] = useState(false)
    const [inquiry, setInquiry] = useState(null)
    const [message, setMessage] = useState(null)
    const searchInquiryFormik = useFormik({
        initialValues: {
            code: ''
        },
        onSubmit: async (data, {resetForm}) => {
            setLoading(true)
            await findInquiryByCode(data.code).then((resp) => {
                setMessage(null)
                setInquiry(resp.data)
                resetForm();
            }).catch((err) => {
                setInquiry(null)
                setMessage(err.message)
            })
            setLoading(false)
        }
    })
    return (
        <div>
            {
                loading ? <Spinner/> :
                    <div id="suivi" style={{marginTop: '120px'}}>
                        <div className="container-fluid banner mb-5">
                            <div className="container d-flex justify-content-center">
                                <div className="">
                                    <div
                                        className="appointment-form h-100 d-flex flex-column justify-content-center p-5"
                                        data-wow-delay="0.6s">
                                        <h1 className="text-black mb-1 text-center">Dossier</h1>
                                        {message ?
                                            <h5 className="text-center mt-2" style={{color: "red"}}>
                                                Le code renseigné n'est pas valide.
                                            </h5> : null}
                                        <form onSubmit={searchInquiryFormik.handleSubmit}>
                                            <div className="row g-3">
                                                <h4 className="text-black mb-1 mt-5 text-center">Veuillez saisir votre
                                                    numéro de
                                                    suivi</h4>
                                                <div className="col-12 col-sm-12">
                                                    <TextField size={"small"}
                                                               value={searchInquiryFormik.values.code}
                                                               name="code"
                                                               onChange={searchInquiryFormik.handleChange}
                                                               placeholder="Numéro de suivi" fullWidth
                                                               style={{backgroundColor: "white"}}/>
                                                </div>

                                            </div>

                                            <div
                                                className="d-flex col-12 mt-4 align-items-center justify-content-center">
                                                <button className="btn btn-primary w-100 py-3"
                                                        type='button'
                                                        onClick={searchInquiryFormik.handleSubmit}>
                                                    Suivre mon dossier
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {inquiry ? <Details data={inquiry}/> : null}
        </div>
    );
}

export default FormSuivi;
