import React from 'react';
import img1 from "../assets/img/logo-dass.webp"
function Offer(props) {
    return (
        <div className="container-fluid bg-offer my-3 py-3 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 wow zoomIn" data-wow-delay="0.6s">
                        <div className="offer-text text-center rounded p-5">
                            <h1 className="display-5">DASS</h1>
                            <p className="text-white mb-4">
                                <img src={img1}/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Offer;
