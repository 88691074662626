import React from 'react';
import Search from "../components/Search";
import Carousel from "../components/Carousel";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Service from "../components/Service";
import About from "../components/About";
import Offer from "../components/Offer";
import Testimonial from "../components/Testimonial";
import Teams from "../components/Teams";
import Gallery from "../components/Gallery";

function Homepage(props) {
    return (
        <div>
            <Search/>
            <Carousel/>
            <Banner/>
            <About/>
            {/*<Appointment/>
            <Service/>*/}
            <Gallery/>
            <Offer/>
            {/*<Pricing/>
            <Testimonial/>
            <Teams/>*/}
            <Footer/>
        </div>
    );
}

export default Homepage;
