import React from 'react';

function Footer(props) {
    return (
        <div id="contact">
            <div className="container-fluid bg-dark text-light py-5 wow fadeInUp mt-5" data-wow-delay="0.3s">
                <div className="container pt-5">
                    <div className="row g-5 pt-4">
                        <div className="col-lg-4 col-md-6">
                            <h3 className="text-white mb-4">DASS</h3>
                            <div className="d-flex flex-column justify-content-start">
                                <p>DASS, Organisme gouvernemental est chargée mise en œuvre politique municipale en matière de santé publique, de prévention.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">

                        </div>
                        <div className="col-lg-2 col-md-6">

                        </div>
                        <div className="col-lg-4 col-md-6">
                            <h3 className="text-white mb-4">Contactez-nous</h3>
                            <div className="">
                                <p>Contact : +221 33 849 08  21</p><br/>
                                {/* <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i
                                    className="fab fa-twitter fw-normal"></i></a>*/}
                                <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" target="_blank" href="https://www.facebook.com/profile.php?id=100069383282053&locale=fr_FR"><i
                                    className="fab fa-facebook-f fw-normal"></i></a>
                                {/* <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i
                                    className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i
                                    className="fab fa-instagram fw-normal"></i></a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Back to Top*/}
            <a href="#" className="btn btn-lg btn-color btn-lg-square rounded back-to-top"><i
                className="bi bi-arrow-up"></i></a>

        </div>
    );
}

export default Footer;
