import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL + "/v1/";

export const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json"
    },
});

instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res.data;
    },
    async (err) => {
        return Promise.reject(JSON.parse(err.request.response))
    }
);
