import React from 'react';
import about from "../assets/img/dass-about.jpeg"

function About(props) {
    return (
        <div className="about-back">
            <div  id="about" className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title mb-4">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    A propos de nous
                                </h5>
                                <h4 className="display-5 mb-0">DASS</h4>
                            </div>
                            <h4 className="text-body fst-italic mb-4">Direction de l'Action Sanitaire et des Services Aux
                                Personnes </h4>
                            <p className="mb-4 about">La Direction de l’Action Sanitaire et des Services aux Personnes (DASS)
                                est une Direction de la l'Institution Municipale de la Ville de Dakar, chargée de la
                                mise en œuvre de la stratégie municipale d'appui à la politique du Gouvernement en
                                matière de santé publique, de prévention et d’hygiène et d'action sociale en liaison
                                avec les départements ministériels concernés.
                                <br/>
                                <br/>
                                AInsi, la DASS développe un certain nombre de produits qui caractérisent l'offre socio
                                sanitaire de la Ville de Dakar, notamment dans le renforcement du dispositif sanitaire
                                de la ville, et la prise en charge de la demande sociale à travers des stratégies d'auto
                                prise en charge des populations modestes, et la prise en charge médicale des citoyens et
                                citoyennes dans le besoin.</p>

                            <div className="d-flex justify-content-center">
                                <a href="#demande" className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                                   data-wow-delay="0.6s">Soumettre une demande</a>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn about-img" data-wow-delay="0.9s"
                                     src={about} style={{objectFit: "cover"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
