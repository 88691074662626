import React from 'react';
import logo from "../assets/img/logo-dass.webp"

function Navbar(props) {
    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
                <a href="/" className="navbar-brand">
                    <h1 className="m-0 text-primary"><img src={logo} height="70px" alt={"Logo DASS"}/><span
                        className="logo-dass"> DASS</span></h1>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <a href="/" className="nav-item nav-link active">Accueil</a>
                        <a href="/#about" className="nav-item nav-link">A propos de nous</a>
                        {/*<a href="/#about" className="nav-item nav-link">A propos de nous</a>*/}
                        <a href="/#contact" className="nav-item nav-link">Contact</a>
                        <a href="/suivi" className="nav-item nav-link d-lg-none ">Suivre ma demande</a>
                        <a href="/#demande" className="nav-item nav-link d-lg-none">Faire une demande</a>
                        {/*<div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu m-0">
                                <a href="price.html" className="dropdown-item">Pricing Plan</a>
                                <a href="team.html" className="dropdown-item">Our Dentist</a>
                                <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                <a href="appointment.html" className="dropdown-item">Appointment</a>
                            </div>
                        </div>*/}
                    </div>
                    <a href="/suivi" className="btn btn-primary-reversed py-2 px-4 ms-3 d-none d-lg-block">SUIVRE MA DEMANDE</a>
                    <a href="/#demande" className="btn btn-primary py-2 px-4 ms-3 d-none d-lg-block">FAIRE UNE DEMANDE</a>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
