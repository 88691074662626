import React from 'react';
import {Box, Button, Modal, Typography} from "@mui/material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function ModalComponent({openModal, setOpenModal, codeNumber}) {
    // const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" className="text-center" variant="h5" component="h2">
                        Numéro de dossier
                    </Typography>
                    <Typography id="modal-modal-description" className="font-weight-bold text-center text-primary"
                                variant='h6' sx={{mt: 2}}>
                        {codeNumber}
                    </Typography>
                    <Box className="d-flex justify-content-end mt-2">
                        <Button onClick={handleClose}>
                            Fermer
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default ModalComponent;
