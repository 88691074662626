import React, {useState} from 'react';
import {FormControlLabel, MenuItem, Radio, RadioGroup, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {addInquiry} from "../services/inquiry";
import Spinner from "./Spinner";
import ModalComponent from "./ModalComponent";
import {DatePicker, frFR, LocalizationProvider} from "@mui/x-date-pickers";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import {isAlphaNumeric} from "../utils";

const situation_matrimonial = [
    {
        label: 'Marié(e)',
        value: 'married',
    },
    {
        label: 'Célibataire',
        value: 'single',
    },
    {
        label: 'Divorcé(e)',
        value: 'divorced',
    },
    {
        label: 'Veuf(ve)',
        value: 'widower',
    },
];

const town = [
    {
        label: 'BISCUITERIE',
        value: 'BISCUITERIE',
    },
    {
        label: 'CAMBERENE',
        value: 'CAMBERENE',
    },
    {
        label: 'DIEUPPEUL DERKLE',
        value: 'DIEUPPEUL DERKLE',
    },
    {
        label: 'FANN POINT E',
        value: 'FANN POINT E',
    },
    {
        label: 'GOREE',
        value: 'GOREE',
    },
    {
        label: 'GRAND DAKAR',
        value: 'GRAND DAKAR',
    },
    {
        label: 'GRAND YOFF',
        value: 'GRAND YOFF',
    },
    {
        label: 'GUEULE TAPEE FASS COLOBANE',
        value: 'GUEULE TAPEE FASS COLOBANE',
    },
    {
        label: 'HLM',
        value: 'HLM',
    },
    {
        label: 'HANN BEL AIR',
        value: 'HANN BEL AIR',
    },
    {
        label: 'MEDINA',
        value: 'MEDINA',
    },
    {
        label: 'MERMOZ',
        value: 'MERMOZ',
    },
    {
        label: 'SACRE COEUR',
        value: 'SACRE COEUR',
    },
    {
        label: 'NGOR',
        value: 'NGOR',
    },
    {
        label: 'OUAKAM',
        value: 'OUAKAM',
    },
    {
        label: 'PARCELLES ASSAINIES',
        value: 'PARCELLES ASSAINIES',
    },
    {
        label: 'PATTE D\'OIE',
        value: 'PATTE D\'OIE',
    },
    {
        label: 'PLATEAU',
        value: 'PLATEAU',
    },
    {
        label: 'SICAP LIBERTE',
        value: 'SICAP LIBERTE',
    },
    {
        label: 'YOFF',
        value: 'YOFF',
    }
];

const paroisses = [
    {
        label: 'Paroisse Marie Immaculée des Parcelles Assainies',
        value: 'PMI',
    },
    {
        label: 'Paroisse Saint Paul de Grand Yoff',
        value: 'PSPG',
    },
    {
        label: 'Paroisse Martyrs de l’Ouganda',
        value: 'PMO',
    },
    {
        label: 'Paroisse Saint Pierre des Baobabs',
        value: 'PSPB',
    },
    {
        label: 'Paroisse Saint Joseph de Médina',
        value: 'PSJM',
    },
    {
        label: 'Paroisse Malenfant du Plateau',
        value: 'PMP',
    },
    {
        label: 'Paroisse DON BOSCO Nord Foire',
        value: 'PDB',
    },
    {
        label: 'Paroisse Saint Maurice d’Angers',
        value: 'PSM',
    }
]

const houses = [
    {
        label: 'Locataire',
        value: 'Locataire',
    },
    {
        label: 'Propriétaire',
        value: 'Propriétaire',
    },
    {
        label: 'Sans domicile',
        value: 'Sans domicile',
    },
    {
        label: 'Hébergé/Sous couvert',
        value: 'Hébergé/Sous couvert',
    }
]

const situation_professionals = [
    {
        label: 'Salarié',
        value: 'salary',
    },
    {
        label: 'Retraité',
        value: 'pensioner',
    },
    {
        label: 'Sans emploi',
        value: 'no job',
    },
    {
        label: 'Invalide',
        value: 'invalid',
    },
    {
        label: 'En formation/En stage',
        value: 'in training/on internship',
    }
]

function Banner(props) {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [code, setCode] = useState(null)

    const validationSchema = Yup.object({
        firstname: Yup.string().min(3, 'Minimum 3 charactères').required('Le nom est obligatoire'),
        lastname: Yup.string().required('Le prénom est obligatoire').nullable(),
        date_of_birth: Yup.date().required('La date de naissance est obligatoire').nullable(),
        type_request: Yup.string().required('Type de demande'),
        church: Yup.string().when('type_request', (name, schema) => {
            if (name[0] === 'physical') return schema.required("Le nom de la paroisse est obligatoire.")
        }),
        cid_number: Yup.string()
            .min(12, "Minimum 12 caractères")
            .max(14, "Maximum 14 caractères")
            .test('alpha-numeric', 'Le champ doit être un alpha numérique.', (value) => {
                return isAlphaNumeric(value)
            })
            .required("Le numéro de CNI est obligatoire"),
        phone_numbers: Yup.string()
            .required("Le numéro de téléphone est obligatoire")
            .matches(/^[0-9]+$/, "Contient uniquement des chiffres."),
        number_of_children: Yup.string()
            .matches(/^[0-9]+$/, "Contient uniquement des chiffres.")
            .required("Mettre 0(zéro) si vous en avez pas."),
        situation_matrimonial: Yup.string().required('La situation matrimoniale est obligatoire'),
        town: Yup.string().required('L\'adresse est obligatoire'),
        address: Yup.string().required('La commune est obligatoire'),
        profession: Yup.string().required('La profession est obligatoire'),
        housing_status: Yup.string().required('La situation de logement est obligatoire'),
        source_of_income: Yup.string()
            .matches(/^[0-9]+$/, "Ce champ doit être est un montant")
            .required('Les frais de revenues sont obligatoire'),
        healthcare: Yup.string()
            .matches(/^[0-9]+$/, "Ce champ doit être est un montant")
            .required('Les frais de soins sont obligatoire'),
        supply: Yup.string()
            .matches(/^[0-9]+$/, "Ce champ doit être est un montant")
            .required('Les frais d\'alimentation sont obligatoire'),
        education: Yup.string()
            .matches(/^[0-9]+$/, "Ce champ doit être est un montant")
            .required('Les frais d\'éducation son obligatoire'),
        situation_professional: Yup.string().required('La situation professionnelle est obligatoire'),
        incomes: Yup.string()
            .matches(/^[0-9]+$/, "Ce champ doit être est un montant")
            .when("situation_professional", (name, schema) => {
                if (name[0] !== 'no job' && name[0] !== 'invalid' && name[0] !== undefined)
                    return schema.required("Le montant est obligatoire.")
            })
    });


    const addInquiryFormik = useFormik({
        initialValues: {
            firstname: '',
            type_request: 'non-physical',
            church: '',
            lastname: '',
            date_of_birth: dayjs(new Date()),
            cid_number: '',
            situation_matrimonial: '',
            number_of_children: '',
            address: '',
            town: '',
            profession: '',
            phone_numbers: '',
            housing_status: '',
            source_of_income: '',
            healthcare: '',
            supply: '',
            education: '',
            incomes: '',
            situation_professional: '',
        },
        validationSchema,
        onSubmit: async (data, {resetForm}) => {
            if (data.incomes === '') {
                data.incomes = '0';
            }
            setLoading(true)
            await addInquiry(data).then((res) => {
                setOpenModal(true)
                setCode(res.data.file_number)
                resetForm();
            }).catch((err) => {
                setMessage(err.message)
            })
            setLoading(false)
        }
    })
    return (
        <div>
            <ModalComponent openModal={openModal} setOpenModal={setOpenModal} codeNumber={code}/>
            {
                loading ? <Spinner/> :
                    <div id="demande">
                        <div className="container-fluid banner mb-5">
                            <div className="container d-flex">
                                <div className="">
                                    <div
                                        className="appointment-form h-100 d-flex flex-column justify-content-center p-5 wow zoomIn"
                                        data-wow-delay="0.6s">
                                        <h1 className="text-black mb-4 text-center">Soumettre une demande</h1>
                                        <form onSubmit={addInquiryFormik.handleSubmit}>
                                            <div className="row g-3">
                                                <h4 className="text-black mb-1 mt-5">Type de demande</h4>
                                                <div className="col-6 col-sm-6">
                                                    <RadioGroup
                                                        row
                                                        value={addInquiryFormik.values.type_request}
                                                        onChange={addInquiryFormik.handleChange}
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="type_request"
                                                    >
                                                        <FormControlLabel value="non-physical" control={<Radio/>}
                                                                          label="Non physique"/>
                                                        <FormControlLabel value="physical" control={<Radio/>}
                                                                          label="Physique"/>
                                                    </RadioGroup>
                                                </div>
                                                {addInquiryFormik.values.type_request === 'physical' ?
                                                    <div className="col-6 col-sm-6">
                                                        <TextField
                                                            size={"small"}
                                                            fullWidth
                                                            select
                                                            name="church"
                                                            label="Nom de la paroisse"
                                                            value={addInquiryFormik.values.church}
                                                            error={addInquiryFormik.touched.church && addInquiryFormik.errors.church}
                                                            helperText={addInquiryFormik.touched.church && addInquiryFormik.errors.church}
                                                            onChange={addInquiryFormik.handleChange}
                                                            onFocus={addInquiryFormik.handleBlur("church")}
                                                            style={{backgroundColor: "white"}}>
                                                            {paroisses.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div> : null}

                                                <h4 className="text-black mb-1 mt-5">Identification du demandeur</h4>

                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"}
                                                               placeholder="Prénom(s)"
                                                               fullWidth
                                                               name="firstname"
                                                               value={addInquiryFormik.values.firstname}
                                                               error={addInquiryFormik.touched.firstname && addInquiryFormik.errors.firstname}
                                                               helperText={addInquiryFormik.touched.firstname && addInquiryFormik.errors.firstname}
                                                               onChange={addInquiryFormik.handleChange}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"}
                                                               placeholder="Nom"
                                                               fullWidth
                                                               name="lastname"
                                                               multiline
                                                               value={addInquiryFormik.values.lastname}
                                                               error={addInquiryFormik.touched.lastname && addInquiryFormik.errors.lastname}
                                                               helperText={addInquiryFormik.touched.lastname && addInquiryFormik.errors.lastname}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               onChange={addInquiryFormik.handleChange}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}
                                                                          localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                label="Date de naissance"
                                                                defaultValue={addInquiryFormik.values.date_of_birth}
                                                                slotProps={{
                                                                    textField: {
                                                                        name: 'date_of_birth',
                                                                        helperText: addInquiryFormik.touched.date_of_birth && addInquiryFormik.errors.date_of_birth,
                                                                        error: addInquiryFormik.touched.date_of_birth && addInquiryFormik.errors.date_of_birth,
                                                                        onFocus: addInquiryFormik.handleBlur,
                                                                        onChange: addInquiryFormik.handleChange,
                                                                        size: 'small',
                                                                        fullWidth: true
                                                                    }
                                                                }}/>
                                                        </DemoContainer>
                                                    </LocalizationProvider>

                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"} placeholder="Numéro de NIN" fullWidth
                                                               name="cid_number"
                                                               value={addInquiryFormik.values.cid_number}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.cid_number && addInquiryFormik.errors.cid_number}
                                                               helperText={addInquiryFormik.touched.cid_number && addInquiryFormik.errors.cid_number}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"}
                                                               fullWidth
                                                               select
                                                               name="situation_matrimonial"
                                                               label="Situation matrimoniale"
                                                               value={addInquiryFormik.values.situation_matrimonial}
                                                               error={addInquiryFormik.touched.situation_matrimonial && addInquiryFormik.errors.situation_matrimonial}
                                                               helperText={addInquiryFormik.touched.situation_matrimonial && addInquiryFormik.errors.situation_matrimonial}
                                                               onChange={addInquiryFormik.handleChange}
                                                               onFocus={addInquiryFormik.handleBlur("situation_matrimonial")}
                                                               style={{backgroundColor: "white"}}>
                                                        {situation_matrimonial.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"} placeholder="Nombre d'enfants en charge"
                                                               fullWidth
                                                               name="number_of_children"
                                                               value={addInquiryFormik.values.number_of_children}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.number_of_children && addInquiryFormik.errors.number_of_children}
                                                               helperText={addInquiryFormik.touched.number_of_children && addInquiryFormik.errors.number_of_children}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"} placeholder="Profession" fullWidth
                                                               name="profession"
                                                               value={addInquiryFormik.values.profession}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.profession && addInquiryFormik.errors.profession}
                                                               helperText={addInquiryFormik.touched.profession && addInquiryFormik.errors.profession}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"} placeholder="Adresse" fullWidth
                                                               name="address"
                                                               value={addInquiryFormik.values.address}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.address && addInquiryFormik.errors.address}
                                                               helperText={addInquiryFormik.touched.address && addInquiryFormik.errors.address}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"}
                                                               fullWidth
                                                               name="town"
                                                               label="Commune"
                                                               select
                                                               value={addInquiryFormik.values.town}
                                                               onChange={addInquiryFormik.handleChange}
                                                               onFocus={addInquiryFormik.handleBlur("town")}
                                                               error={addInquiryFormik.touched.town && addInquiryFormik.errors.town}
                                                               helperText={addInquiryFormik.touched.town && addInquiryFormik.errors.town}
                                                               style={{backgroundColor: "white"}}>
                                                        {town.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"} placeholder="Contacts" fullWidth
                                                               name="phone_numbers"
                                                               value={addInquiryFormik.values.phone_numbers}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.phone_numbers && addInquiryFormik.errors.phone_numbers}
                                                               helperText={addInquiryFormik.touched.phone_numbers && addInquiryFormik.errors.phone_numbers}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={"small"}
                                                        fullWidth
                                                        select
                                                        name="housing_status"
                                                        label="Situation de logement"
                                                        value={addInquiryFormik.values.housing_status}
                                                        error={addInquiryFormik.touched.housing_status && addInquiryFormik.errors.housing_status}
                                                        helperText={addInquiryFormik.touched.housing_status && addInquiryFormik.errors.housing_status}
                                                        onChange={addInquiryFormik.handleChange}
                                                        onFocus={addInquiryFormik.handleBlur("housing_status")}
                                                        style={{backgroundColor: "white"}}>
                                                        {houses.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField size={"small"}
                                                               placeholder="Le montant de mes revenus"
                                                               fullWidth
                                                               name="source_of_income"
                                                               value={addInquiryFormik.values.source_of_income}
                                                               onChange={addInquiryFormik.handleChange}
                                                               error={addInquiryFormik.touched.source_of_income && addInquiryFormik.errors.source_of_income}
                                                               helperText={addInquiryFormik.touched.source_of_income && addInquiryFormik.errors.source_of_income}
                                                               onFocus={addInquiryFormik.handleBlur}
                                                               style={{backgroundColor: "white"}}/>
                                                </div>
                                                <h4 className="text-black mb-1 mt-5">Charges</h4>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={'small'}
                                                        placeholder="Le montant de mes soins"
                                                        fullWidth
                                                        name="healthcare"
                                                        value={addInquiryFormik.values.healthcare}
                                                        onChange={addInquiryFormik.handleChange}
                                                        error={addInquiryFormik.touched.healthcare && addInquiryFormik.errors.healthcare}
                                                        helperText={addInquiryFormik.touched.healthcare && addInquiryFormik.errors.healthcare}
                                                        onFocus={addInquiryFormik.handleBlur}
                                                        style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={'small'}
                                                        placeholder="Mes frais d'Alimentation"
                                                        fullWidth
                                                        name="supply"
                                                        value={addInquiryFormik.values.supply}
                                                        onChange={addInquiryFormik.handleChange}
                                                        error={addInquiryFormik.touched.supply && addInquiryFormik.errors.supply}
                                                        helperText={addInquiryFormik.touched.supply && addInquiryFormik.errors.supply}
                                                        onFocus={addInquiryFormik.handleBlur}
                                                        style={{backgroundColor: "white"}}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={'small'}
                                                        placeholder="Mes frais d'Education"
                                                        fullWidth
                                                        name="education"
                                                        value={addInquiryFormik.values.education}
                                                        onChange={addInquiryFormik.handleChange}
                                                        error={addInquiryFormik.touched.education && addInquiryFormik.errors.education}
                                                        helperText={addInquiryFormik.touched.education && addInquiryFormik.errors.education}
                                                        onFocus={addInquiryFormik.handleBlur}
                                                        style={{backgroundColor: "white"}}/>
                                                </div>
                                                <h4 className="text-black mb-1 mt-5">Situation professionnelle</h4>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={"small"}
                                                        fullWidth
                                                        select
                                                        name="situation_professional"
                                                        label="Situation professionnelle"
                                                        value={addInquiryFormik.values.situation_professional}
                                                        error={addInquiryFormik.touched.situation_professional && addInquiryFormik.errors.situation_professional}
                                                        helperText={addInquiryFormik.touched.situation_professional && addInquiryFormik.errors.situation_professional}
                                                        onChange={addInquiryFormik.handleChange}
                                                        onFocus={addInquiryFormik.handleBlur("situation_professional")}
                                                        style={{backgroundColor: "white"}}>
                                                        {situation_professionals.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <TextField
                                                        size={'small'}
                                                        placeholder="Le montant de mes revenues"
                                                        fullWidth
                                                        name="incomes"
                                                        value={addInquiryFormik.values.incomes}
                                                        onChange={addInquiryFormik.handleChange}
                                                        error={addInquiryFormik.touched.incomes && addInquiryFormik.errors.incomes}
                                                        helperText={addInquiryFormik.touched.incomes && addInquiryFormik.errors.incomes}
                                                        onFocus={addInquiryFormik.handleBlur}
                                                        style={{backgroundColor: "white"}}/>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div
                                                    className="col-12 d-flex align-items-center justify-content-center">
                                                    <button className="btn btn-primary py-3 w-75"
                                                            onClick={addInquiryFormik.handleSubmit}>
                                                        FAIRE UNE DEMANDE
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Banner;
