import React from 'react';
import team1 from "../assets/img/team-1.jpg"
import team2 from "../assets/img/team-2.jpg"
import team3 from "../assets/img/team-3.jpg"
import team4 from "../assets/img/team-4.jpg"
import team5 from "../assets/img/team-5.jpg"

function Teams(props) {
    return (
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                        <div className="section-title bg-light rounded h-100 p-5">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                Nos produits
                            </h5>
                            <h1 className="display-6 mb-4">lorem ipsum</h1>
                            <a href="appointment.html" className="btn btn-primary py-3 px-5">Créer un rapport</a>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                        <div className="team-item">
                            <div className="position-relative rounded-top" style={{"z-index": 1}}>
                                <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            </div>
                            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                <h4 className="mb-2">Service 1</h4>
                                <p className="text-primary mb-0">Lorem ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                        <div className="team-item">
                            <div className="position-relative rounded-top" style={{"z-index": 1}}>
                                <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            </div>
                            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                <h4 className="mb-2">Service 1</h4>
                                <p className="text-primary mb-0">Lorem ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="position-relative rounded-top" style={{"z-index": 1}}>
                                <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            </div>
                            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                <h4 className="mb-2">Service 1</h4>
                                <p className="text-primary mb-0">Lorem ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                        <div className="team-item">
                            <div className="position-relative rounded-top" style={{"z-index": 1}}>
                                <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            </div>
                            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                <h4 className="mb-2">Service 1</h4>
                                <p className="text-primary mb-0">Lorem ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                        <div className="team-item">
                            <div className="position-relative rounded-top" style={{"z-index": 1}}>
                                <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            </div>
                            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                <h4 className="mb-2">Service 1</h4>
                                <p className="text-primary mb-0">Lorem ipsum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Teams;
