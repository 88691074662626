import React from 'react';
import Search from "../components/Search";
import Footer from "../components/Footer";
import FormSuivi from "./FormSuivi";

function Suivi(props) {
    return (
        <div>
            <Search/>
            <FormSuivi/>
            <Footer/>
        </div>
    );
}

export default Suivi;
