import React from 'react';
import before from "../assets/img/service-1.jpg"
import after from "../assets/img/after.jpg"
import serv1 from "../assets/img/service-1.jpg"
import serv2 from "../assets/img/service-2.jpg"
import serv3 from "../assets/img/service-3.jpg"
import serv4 from "../assets/img/service-4.jpg"

function Service(props) {
    return (
        <div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-lg-5 wow zoomIn" data-wow-delay="0.3s" style={{"min-height": "400px"}}>
                            <div className="twentytwenty-container position-relative h-100 rounded overflow-hidden">
                                <img className="position-absolute w-100 h-100" src={before}
                                     style={{objectFit: "cover"}}/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    Nos services
                                </h5>
                                <h1 className="display-5 mb-0">Lorem ipsum Lorem ipsum Lorem ipsum</h1>
                            </div>
                            <div className="row g-5">
                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s">
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" width="500" height="200" src={serv1} alt=""/>
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Lorem ipsum</h5>
                                    </div>
                                </div>
                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.9s">
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" width="500" height="200" src={serv1} alt=""/>
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Lorem ipsum</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-7">
                            <div className="row g-5">
                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.3s">
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" width="500" height="200" src={serv1} alt=""/>
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Lorem ipsum</h5>
                                    </div>
                                </div>
                                <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s">
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" width="500" height="200" src={serv1} alt=""/>
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Lorem ipsum</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 service-item wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
                                <h3 className="text-white mb-3">Créer un rapport</h3>
                                <p className="text-white mb-3">
                                    Lorem ipsum
                                </p>
                                <h2 className="text-white mb-0">+221 77 777 77 77</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
