import React from 'react';

const status = [
    {
        label: 'En cours',
        value: 'IN_PROGRESS',
        bgColor: '#d5d530'
    },
    {
        label: 'Validé',
        value: 'VALIDATED',
        bgColor: 'green'
    },
    {
        label: 'Rejeté',
        value: 'REJECTED',
        bgColor: 'red'
    }

];

function Details({data}) {
    return (
        <div id="suivi" style={{marginTop: '120px'}}>
            <div className="container-fluid banner mb-5">
                <div className="container d-flex justify-content-center">
                    <div className="">
                        <div
                            className="appointment-form h-100 d-flex flex-column justify-content-center p-5 wow zoomIn"
                            data-wow-delay="0.6s">
                            <h1 className="text-black mb-1 text-center">Dossier</h1>
                            <div className="row g-3">

                                <h4 className="text-black mb-1 mt-5">Identification du demandeur</h4>

                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Prénom :</span> {data.firstname}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Nom :</span> {data.lastname}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span
                                        className="demand-user">Date et lieu de naissance :</span> {data.date_and_place_of_birth}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">NIN :</span> {data.cid_number}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span
                                        className="demand-user">Situation matrimonial :</span> {data.situation_matrimonial}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span
                                        className="demand-user">Nombre d'enfants :</span> {data.number_of_children}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Adresse :</span> {data.address}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Profession :</span> {data.profession}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Contacts :</span> {data.phone_numbers}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span
                                        className="demand-user">Situation de logement :</span> {data.housing_status}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span
                                        className="demand-user">Source de revenus :</span> {data.source_of_income}</span>
                                </div>
                                <br/>
                                <h4 className="text-black mb-1 mt-5">Charges:</h4>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Soins :</span> {data.healthcare}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Alimentation :</span> {data.supply}</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span><span className="demand-user">Education :</span> {data.education}</span>
                                </div>
                                <br/>
                                <div className="col-12 col-sm-12">
                                    <span className="demand-user">Statut  : </span>
                                    <span
                                        className="mr-1 status p-1"
                                        style={{
                                            backgroundColor: status.find(st=>st.value === data.status).bgColor
                                        }}
                                    > {status.find(st=>st.value === data.status).label}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;
