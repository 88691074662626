import React from 'react';
import car2 from "../assets/img/dass-img1.jpeg"
import car1 from "../assets/img/dass-img2.jpeg"

function Carousel(props) {
    return (
        <div>
            <div className="container-fluid p-0">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100 img-size" src={car1} alt="Image"/>
                            <div
                                className="carousel-caption d-flex flex-column px-10 justify-content-center">
                                <div className="p-3">
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown text-lg-start">
                                        Rapport d'enquête sociale
                                    </h5>
                                    <h5 className="display-1 text-lg-start text-white mb-md-4 animated zoomIn">
                                        Secours evenementiels
                                    </h5>
                                    <h5></h5>
                                    <a href="#demande"
                                       className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft call-to-action">Faire une demande</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100 img-size" src={car2} alt="Image" style={{backgroundSize:'cover'}}/>
                            <div
                                className="carousel-caption d-flex flex-column px-10 justify-content-center">
                                <div className="p-3">
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown text-lg-start">
                                        Rapport d'enquête sociale
                                    </h5>
                                    <h5 className="display-1 text-lg-start text-white mb-md-4 animated zoomIn">
                                        Secours evenementiels
                                    </h5>
                                    <a href="#demande"
                                       className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft call-to-action">Faire une demande</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
